import React, { useEffect, useState } from 'react';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// Context
import { defaultUser, UserProvider } from '../context/UserContext';
// libs
import { onAuthStateChange } from '../utils/firebase';
// components
import Breweries from '../pages/breweries/Breweries';
import Home from '../pages/home/Home';
import Users from '../pages/users/Users';
import Login from '../pages/login/Login';
import LogoForm from '../pages/brewery/overrideBrewery/logoForm/LogoForm';
import Brewery from '../pages/brewery/Brewery';
import EditUserForm from '../pages/users/EditUserForm';
// import AddBrewery from '../pages/brewery/editbrewery/AddBrewery';
//
import GlobalAdminRoute from './GlobalAdminRoute';

function AppRoutes() {
  const [user, setUser] = useState(defaultUser);

  useEffect(() => {
    const unsubscribe = onAuthStateChange(setUser);
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <UserProvider value={user}>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route
            exact
            path="/breweries"
            element={
              <GlobalAdminRoute>
                <Breweries />
              </GlobalAdminRoute>
            }
          />
          <Route
            exact
            path="/brewery/:id"
            element={
              <GlobalAdminRoute>
                <Brewery />
              </GlobalAdminRoute>
            }
          />
          <Route
            exact
            path="/brewery/logo/edit/:id"
            element={
              <GlobalAdminRoute>
                <LogoForm />
              </GlobalAdminRoute>
            }
          />
          {/* <Route
            exact
            path="/brewery/add"
            element={
              <GlobalAdminRoute>
                <AddBrewery />
              </GlobalAdminRoute>
            }
          /> */}
          <Route
            exact
            path="/users"
            element={
              <GlobalAdminRoute>
                <Users />
              </GlobalAdminRoute>
            }
          />
          <Route
            exact
            path="/user/:id"
            element={
              <GlobalAdminRoute>
                <EditUserForm />
              </GlobalAdminRoute>
            }
          />
        </Routes>
      </Router>
    </UserProvider>
  );
}

export { AppRoutes };
